(function($, exports) {

$('.js-ajax-form').ajaxForm({
    url: mesh_wp_config.wp_admin_ajax,
    data: {
        action: 'mesh_form'
    },
    dataType: 'json',
    beforeSubmit: function(data, $form) {
        var $btn = $form.find('button');
        $btn.data('label', $btn.html()).html('Please wait...');
        $btn.prop('disabled', true);
    },
    success: function(data, status, jqxhr, $form) {
        var $btn = $form.find('button');
        $btn.html($btn.data('label'))
            .prop('disabled', false);


        if(data.success === false) {
            render_mesh_validation_errors(data, status, jqxhr, $form);
            return;
        }
        meshAnalytics.trackEvent('submission', 'Forms', $form.find('[name=_mesh_form]').val());
        render_mesh_form_success(data, status, jqxhr, $form);
    }
});

// add support for jquery-nice-select
$(document).ready(function() {
    // some hackery to support 'faux' placeholder style
    var maybeAddPlaceholderClass = function(e) {
        var placeholder = false;
        var $el = $(e.currentTarget);
        if($el.val() == $el.find('option[disabled]:first-child').val() || $el.val() == null) {
            placeholder = true;
        }
        console.log('pholder', $el.val(), $el.find('option[disabled]:first-child').val(), placeholder);
        $el.next('.nice-select').toggleClass('placeholder', placeholder);
    };
    $('.js-ajax-form select').niceSelect().on('change', maybeAddPlaceholderClass);
    $('.js-ajax-form select').each(function() {
        maybeAddPlaceholderClass({currentTarget: this});
    });
});

exports.render_mesh_validation_errors = function(data, status, jqxhr, $form) {
    $form.find('.error-message, .success-message').detach();
    $form.find('.has-error').removeClass('has-error');

    if(data.success === false) {
        var instanceId = $form.find('input[name="_mesh_form_instance"]').val();
        var formName = $form.find('input[name="_mesh_form"]').val();

        $.each(data.errors, function(key, value) {
            var $error = $('<div class="error-message"><span class="icon-cancel"></span>&nbsp;<span class="text"></span></div>');
            var $field = $form.find("[name='" + instanceId + "[" + key + "]']");

            if (key === '_form') {
                $.each(value, function (i, err) {
                    $error.find('.text').text(err);
                    $form.prepend($error);
                });
            } else if ($field.is(":radio")) {
                $error.find('.text').text(value);
                $form.find("[name='" + instanceId + "[" + key + "]']")
                    .closest('.form-group')
                    .append($error)
                    .addClass('has-error');
            } else {
                $error.find('.text').text(value);
                $form.find("[name='" + instanceId + "[" + key + "]']")
                    .closest('.form-group')
                    .append($error)
                    .addClass('has-error');
            }
        });
    }

    $(window).trigger('mesh.layout_changed');
}

exports.render_mesh_form_success = function(data, status, jqxhr, $form) {
    $form.find('.error-message, .success-message').detach();
    $form.find('.has-error').removeClass('has-error');

    if(data.success === true) {
        var success_message =  'Thank you for your enquiry. We will get back to you as soon as we can.';
        if($form.data('successMessage')) {
            success_message = $form.data('successMessage');
        }
        $form.append(
            $('<div class="success-message"><span class="icon-checkbox-checked"></span><span class="text">' + success_message + '</span></div>')
        );
        $form.find('button[type=submit]').prop('disabled', true);
        $(window).trigger('mesh.layout_changed');
    }
}

})(jQuery, window);