(function($, exports) {

    // tell the parallax function that the layout has changed
    $(window).on('mesh.layout_changed', function() {
        $(window).trigger('resize.px.parallax');
        $.fn.matchHeight._update();
    });

    // tell the parallax function that the layout has changed
    var layoutUpdateTimer;
    exports.meshStartLayoutRefreshInterval = function() {
        layoutUpdateTimer = setInterval(function() {
            $(window).trigger('mesh.layout_changed');
        }, 50);
    };
    exports.meshStopLayoutRefreshInterval = function() {
        clearInterval(layoutUpdateTimer);
    };

})(jQuery, window);