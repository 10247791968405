jQuery(function($) {

    // keep track of any elements that share the same 'top' position,
    // so we can animate them sequentially
    var offsets = {};

    $('.waypoint-animated').each(function() {
        var $el = $(this);

        // record the top position of this element, so that we can animate sequentially
        if(!offsets[$el.offset().top]) {
            offsets[$el.offset().top] = 0;
        }
        if(!$el.hasClass('slick-cloned')) {
            var n = offsets[$el.offset().top]++;
        }

        // the threshold at which point the waypoint is triggered
        var offset = $el.data('waypointOffset') || '95%';

        // if the animation class is not specified manually, add '-play' to the current class
        var waypointClass = $el.data('waypointClass');
        if(!waypointClass) {
            var classes = $el.attr('class').split(' ');
            $.each(classes, function(index, className) {
                if(className.match(/waypoint-animated--/)) {
                    waypointClass = className;
                }
            });
            waypointClass = waypointClass + "-play";
        }

        // set up the waypoint
        $el.waypoint(function(direction) {
            var $self = $(this.element);
            setTimeout(function() {
                $self.addClass(waypointClass);
            }, n * 250);
        }, { offset: offset });

        setInterval(Waypoint.refreshAll, 1000);
    });

});