/**
 * Google Analytics Tracking snippet agnostic event tracker.
 *
 * Provides meshAnalytics.trackEvent() method allowing an event to be recorded
 * on the Analytics profile configured on this site, regardless of the snippet
 * type used to do perform the tracking (i.e. gtag.js, analytics.js, ga.js)
 *
 * @author Tim Anido <tim@pictura.com.au>
 */
(function(exports) {

    exports.meshAnalytics = new function() {
        this.trackEvent = function(action, category, label, value) {
            getBackend().event.apply(this, arguments);
        };
    };

    /**
     * Detect the analytics implementation in use on the page and
     * retrieve the backend interface for it.
     * @returns Backend
     */
    var getBackend = function() {
        if(window.gtag) { return new Backend.GtagJS; }
        if(window.ga) { return new Backend.AnalyticsJS; }
        if(window._gaq) { return new Backend.GaJS; }
        return new Backend.Log;
    };

    var Backend = {
        event: function() {}
    };

    /**
     * This backend accepts a tracker ID - if not given, the backend
     * will default to the tracker ID used to create the gtag object.
     * @param tracker
     * @constructor
     */
    Backend.GtagJS = function(tracker) {
        var _tracker = tracker;
        var _send = function(event, action, data) {
            if(_tracker) data.send_to = _tracker;
            window.gtag(event, action, data);
        }

        this.event = function(action, category, label, value) {
            _send('event', action, {
                event_category: category,
                event_label: label,
                value: value,
            });
        }
    }

    /**
     * This backend accepts a tracker object. If not given, will default
     * to the first configured tracker.
     * @param tracker
     * @constructor
     */
    Backend.AnalyticsJS = function(tracker) {
        var _tracker = tracker || window.ga.getAll()[0];
        var _send = function() {
            _tracker.send.apply(_tracker, arguments);
        }

        this.event = function(action, category, label, value) {
            _send('event', category, action, label, value);
        }
    };

    /**
     * This one has not been tested.. ga.js is very old and rarely used now
     * @constructor
     */
    Backend.GaJS = function() {
        this.event = function(action, category, label, value) {
            window._trackEvent(category, action, label, value);
        }
    }

    /**
     * Simple logging backend, used when no actual tracking snippet has been detected.
     * @constructor
     */
    Backend.Log = function() {
        this.event = function() {
            console && console.log && console.log(arguments);
        }
    }

})(window);